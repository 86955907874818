// import React from "react";
// import { Column, Img, Row, Section, Text } from "@react-email/components";
// import { Tailwind } from "@react-email/tailwind";

// const IfYouNeedSupportSection = () => {
//   // to prevent blinking when user press on the email to open the external email App
//   const handleEmailClick = (e) => {
//     e.preventDefault();
//     window.location.href = "mailto:cs@lokalee.app";
//   };

//   return (
//     <Tailwind>
//       <Text className="text-[20px] leading-[23px] font-bold text-black text-center mb-2">
//         If you need any assistance:
//       </Text>
//       <Section className="bg-[#BAB9B9] p-0.5 text-center text-white">
//         <div className="flex flex-col items-center my-0">
//           <Img
//             src="https://email-assets.lokalee.app/email/emailImages/inbox.png"
//             alt="Inbox"
//             className="w-[20px] h-[33px] pt-4 my-0"
//           />
//           <Text className="text-[11px] leading-[15px] font-bold text-white my-1">
//             Email us:
//             <Row className="justify-center my-1">
//               <Column>
//                 <a
//                   href="mailto:cs@lokalee.app"
//                   onClick={handleEmailClick}
//                   className="text-[11px] leading-[15px] font-bold text-white"
//                 >
//                   cs@lokalee.app
//                 </a>
//               </Column>
//             </Row>
//           </Text>
//         </div>
//       </Section>
//     </Tailwind>
//   );
// };

// export default IfYouNeedSupportSection;
import React from "react";
import { Column, Img, Row, Section, Text } from "@react-email/components";

const IfYouNeedSupportSection = () => {
  // To prevent blinking when user clicks to open the external email app
  const handleEmailClick = (e) => {
    e.preventDefault();
    window.location.href = "mailto:cs@lokalee.app";
  };

  return (
    <>
      <Text
        style={{
          fontSize: "20px", // 'text-[20px]'
          lineHeight: "23px", // 'leading-[23px]'
          fontWeight: "700", // 'font-bold'
          color: "#000000", // 'text-black'
          textAlign: "center", // 'text-center'
          marginBottom: "0.5rem", // 'mb-2'
        }}
      >
        If you need any assistance:
      </Text>
      <Section
        style={{
          backgroundColor: "#BAB9B9", // 'bg-[#BAB9B9]'
          padding: "0.125rem", // 'p-0.5'
          textAlign: "center", // 'text-center'
          color: "#ffffff", // 'text-white'
        }}
      >
        <div
          style={{
            display: "flex", // 'flex'
            flexDirection: "column", // 'flex-col'
            alignItems: "center", // 'items-center'
            marginTop: "0", // 'my-0'
            marginBottom: "0", // 'my-0'
          }}
        >
          <Img
            src="https://email-assets.lokalee.app/email/emailImages/inbox.png"
            alt="Inbox"
            style={{
              width: "20px", // 'w-[20px]'
              height: "33px", // 'h-[33px]'
              paddingTop: "1rem", // 'pt-4'
              marginTop: "0", // 'my-0'
              marginBottom: "0", // 'my-0'
            }}
          />
          <Text
            style={{
              fontSize: "11px", // 'text-[11px]'
              lineHeight: "15px", // 'leading-[15px]'
              fontWeight: "700", // 'font-bold'
              color: "#ffffff", // 'text-white'
              marginTop: "0.25rem", // 'my-1' (top)
              marginBottom: "0.25rem", // 'my-1' (bottom)
            }}
          >
            Email us:
            <Row
              style={{
                justifyContent: "center", // 'justify-center'
                marginTop: "0.25rem", // 'my-1' (top)
                marginBottom: "0.25rem", // 'my-1' (bottom)
              }}
            >
              <Column>
                <a
                  href="mailto:cs@lokalee.app"
                  onClick={handleEmailClick}
                  style={{
                    fontSize: "11px", // 'text-[11px]'
                    lineHeight: "15px", // 'leading-[15px]'
                    fontWeight: "700", // 'font-bold'
                    color: "#ffffff", // 'text-white'
                    textDecoration: "none",
                  }}
                >
                  cs@lokalee.app
                </a>
              </Column>
            </Row>
          </Text>
        </div>
      </Section>
    </>
  );
};

export default IfYouNeedSupportSection;
