import * as Yup from "yup";
import checkout from "./form";

const {
  formField: { propertyCode },
} = checkout;

const validationsCompanion = [
  Yup.object().shape({
    [propertyCode.name]: Yup.string().required(propertyCode.errorMsg),
  }),
];

export default validationsCompanion;
