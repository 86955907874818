/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import HeadsetIcon from "@mui/icons-material/Headset";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import {
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  FormLabel,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DetailList from "components/DetailList";
import GreenSwitch from "components/AutoComplete/GreenSwitch";
import ShowHideComponentsPicker from "components/ShowHideComponentsPicker";
import DynamicRadioGroup from "components/DynamicRadioGroup";
import VenuePicker from "components/AsyncSelect/VenuePicker";
import Danger from "components/Danger";
import FormField from "components/FormField";
import MDButton from "components/MDButton";
import AvailabilityDurationPanel from "components/AvailabilityDurationPanel";
import LanguagesPicker from "components/AutoComplete/LanguagesPicker";
import ClearIcon from "@mui/icons-material/Clear";
import BadgePicker from "components/AutoComplete/BadgePicker";
import PinPicker from "components/AsyncSelect/PinPicker";
import PickupTypePicker from "components/AutoComplete/PickupTypePicker";
import AvailabilityPickupPanel from "components/AvailabilityPickupPanel";
import PinsPicker from "components/AutoComplete/PinsPicker";
import AvailabilityInclusionPanel from "components/AvailabilityInclusionPanel";
import AvailabilityExclusionPanel from "components/AvailabilityExclusionPanel";
import TitlePicker from "components/AutoComplete/TitlePicker";
import AccessibilityForm from "./AccessibilityForm";

const Content = ({
  formData,
  editPage,
  detailPage,
  selectedVenue,
  handleVenueChange,
  selectedPin,
  setSelectedPin,
  setSelectedVenue,
  selectedPickupPoint,
  setSelectedPickupPoint,
  handlePinChange,
  handlePickupPointChange,
  selectedTitleInc,
  setSelectedTitleInc,
  selectedTitleExc,
  setSelectedTitleExc,
  handleTitleIncChange,
  handleTitleExcChange,
}) => {
  const { formField, values, errors, touched, setFieldValue } = formData;
  const {
    isOneVenue,
    venues,
    durationRadio,
    day,
    hour,
    min,
    from,
    to,
    day2,
    hour2,
    min2,
    from2,
    to2,
    durationRadio2,
    description,
    languages,
    skipTheLine,
    containStops,
    titlePkr,
    titlePkrExc,
    // category,
    // categoryExc,
    // titlePkr,
    // inclusions,
    // exclusions,
    // typeText,
    // titlePkrExc,
    // typeTextExc,
    // category,
    // categoryExc,
    // titlePkr,
    typeText,
    // titlePkrExc,
    typeTextExc,

    badge,
    pin,
    pinsStart,
    pinsEnd,
    pickupOptionType,
    pickupPoint,
    pickupType,
    allowCustomTravelerPickup,
    additionalPickupInfo,
    minutesBeforeDepartureTimeForPickup,
    // additionalInfo,
    top,
    highlight,
    accompanied,
    minTravelersPerBooking,
    maxTravelersPerBooking,
    // selectedRadio,
  } = formField;
  const {
    isOneVenue: isOneVenueV,
    venues: venuesV,
    durationRadio: durationRadioV,
    durationRadio2: durationRadio2V,
    description: descriptionV,
    day: dayV,
    hour: hourV,
    min: minV,
    from: fromV,
    to: toV,
    day2: day2V,
    hour2: hour2V,
    min2: min2V,
    from2: from2V,
    to2: to2V,
    languages: languagesV,
    skipTheLine: skipTheLineV,
    containStops: containStopsV,
    accompanied: accompaniedV,
    // exclusions: exclusionsV,
    // inclusions: inclusionsV,
    titlePkr: titlePkrV,
    titlePkrExc: titlePkrExcV,
    typeText: typeTextV,
    typeTextExc: typeTextExcV,
    allowCustomTravelerPickup: allowCustomTravelerPickupV,
    additionalPickupInfo: additionalPickupInfoV,
    minutesBeforeDepartureTimeForPickup: minutesBeforeDepartureTimeForPickupV,
    minTravelersPerBooking: minTravelersPerBookingV,
    maxTravelersPerBooking: maxTravelersPerBookingV,
    // additionalInfo: additionalInfoV,
    top: topV,
    highlight: highlightV,
    pickupOptionType: pickupOptionTypeV,
    physicalDifficultyLevel: physicalDifficultyLevelV,
    pinsStart: pinsStartV,
    pinsEnd: pinsEndV,
  } = values;

  const [selectedDuration, setSelectedDuration] = useState(durationRadioV);
  const [selected2Duration, setSelected2Duration] = useState(durationRadio2V);

  const [itineraryItems, setItineraryItems] = useState(values.itineraryItems || []);
  // const [travelerPickup, setTravelerPickup] = useState(values.travelerPickup || []);
  const [timeType, setTimeType] = useState("MINUTE");
  const [timeType2, setTimeType2] = useState("MINUTE");
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [restrictions, setRestrictions] = useState([]);
  const [languageGuides, setLanguageGuides] = useState([]);
  const [inclusionsValues, setInclusionsValue] = useState(values.inclusions || []);
  const [exclusionsValues, setExclusionsValue] = useState(values.exclusions || []);
  const [startsPins, setStartsPins] = useState(values?.logistics?.start || []);
  const [endsPins, setEndsPins] = useState(values?.logistics?.end || []);
  const [selectedAdditionalInfo, setSelectedAdditionalInfo] = useState([
    "WHEELCHAIR_ACCESSIBLE",
    "STROLLER_ACCESSIBLE",
    "PETS_WELCOME",
    "INFANTS_MUST_SIT_ON_LAPS",
  ]);
  const [allItineraryItems, setAllItineraryItems] = useState([]);

  const [validationMessage, setValidationMessage] = useState("");

  useEffect(() => {
    if (values.inclusions || values.exclusions) {
      setInclusionsValue(values.inclusions || []);
      setExclusionsValue(values.exclusions || []);
    }
  }, [values.inclusions, values.exclusions]);

  useEffect(() => {
    setSelectedLanguages(languagesV);
  }, [languagesV]);

  useEffect(() => {
    const itinItems = Array.isArray(values?.itineraryItems) ? values.itineraryItems : [];
    const itineraryItineraryItems = Array.isArray(values?.itinerary?.itineraryItems)
      ? values.itinerary.itineraryItems
      : [];

    const concatenatedItineraryItems = [...itinItems, ...itineraryItineraryItems];

    setAllItineraryItems(concatenatedItineraryItems);
  }, [values?.itineraryItems, values?.itinerary?.itineraryItems]);

  // useEffect(() => {
  //   const travPickups = Array.isArray(values?.travelerPickup?.pins)
  //     ? values?.travelerPickup?.pins
  //     : [];
  //   const travPickupsVals = Array.isArray(values?.logistics?.travelerPickup?.pins)
  //     ? values?.logistics?.travelerPickup.pins
  //     : [];

  //   const concatenatedItineraryItems = [...travPickups, ...travPickupsVals];

  //   setAllTravelerPickups((prevState) => ({
  //     ...prevState, // Keep the other fields intact
  //     pins: concatenatedItineraryItems, // Update the pins field with the concatenated array
  //   }));
  // }, [values?.travelerPickup, values?.logistics?.travelerPickup, allTravelerPickups?.length]);

  // console.log("testtt")

  useEffect(() => {
    const vals = values?.logistics?.start
      ?.map((item) => item.pin?.id)
      .filter((id) => id !== undefined);
    setStartsPins(vals);
  }, [values?.logistics?.start]);

  useEffect(() => {
    const vals = values?.logistics?.end
      ?.map((item) => item.pin?.id)
      .filter((id) => id !== undefined);
    setEndsPins(vals);
  }, [values?.logistics?.end]);

  useEffect(() => {
    setSelectedLanguages(languagesV);
  }, [languagesV]);

  const venuesOptions = [
    { value: "yes", label: "Yes" },
    { value: "no", label: "No" },
  ];

  const healthRestrictions = [
    { label: "Not recommended for travelers with back problems", value: "NO_BACK_PROBLEMS" },
    { label: "Not recommended for pregnant travelers", value: "NO_PREGNANT" },
    {
      label:
        "Not recommended for travelers with heart problems or other serious medical conditions",
      value: "NO_HEART_PROBLEMS",
    },
  ];

  const accompaniedOptions = [
    { value: true, label: "Yes" },
    { value: false, label: "No" },
  ];

  const durationOptions = [
    { value: "specificDuration", label: "Specific duration" },
    { value: "flexibleDuration", label: "Flexible duration" },
  ];

  const radioOptions = [
    {
      value: "PHYSICAL_EASY",
      label: "Easy",
      helperText: "Most travelers can participate",
    },
    {
      value: "PHYSICAL_MEDIUM",
      label: "Moderate",
      helperText: "Travelers should have a moderate physical fitness level",
    },
    {
      value: "PHYSICAL_HARD",
      label: "Challenging",
      helperText: "Travelers should have a strong physical fitness level",
    },
  ];

  const pickupOptions = [
    {
      value: "PICKUP_EVERYONE",
      label: "Everyone",
    },
    {
      value: "PICKUP_AND_MEET_AT_START_POINT",
      label: "Pickup and meet at start point",
    },
    {
      value: "MEET_EVERYONE_AT_START_POINT",
      label: "Meet everyone at startpoint",
    },
  ];

  const getBadgeLabel = (badgeVal) => {
    switch (badgeVal) {
      case "insider":
        return "Insider tip";
      case "new":
        return "New";
      case "exclusive":
        return "Exclusive";
      case "top_seller":
        return "Top seller";
      case "likely_to_sell_out":
        return "Likely to sell out";
      case "excellent_quality":
        return "Excellent quality";
      case "best_value":
        return "Best value";
      case "best_seller":
        return "Best seller";
      case "once_in_a_lifetime":
        return "Once in a lifetime";
      case "special_offer":
        return "Special offer";
      default:
        return null;
    }
  };

  const helperTextStyle = {
    fontSize: "0.875rem",
    color: "black",
  };

  const handleDurationChange = (name, value) => {
    setSelectedDuration(value);
    setFieldValue(name, value);
  };

  const handleDuration2Change = (name, value) => {
    setSelected2Duration(value);
    setFieldValue(name, value);
  };

  const handleRemoveLanguage = (language) => {
    setSelectedLanguages(selectedLanguages.filter((lang) => lang !== language));
    setFieldValue(
      "languages",
      selectedLanguages.filter((lang) => lang !== language)
    );
  };

  const handleAddInclusion = () => {
    if (titlePkrV?.label?.length > 0) {
      const isOther = titlePkrV?.value === "OTHER"; // Assuming 'other' is the value for "other"
      const newInclusion = {
        type: { label: titlePkrV?.label, value: titlePkrV?.value },
        description: isOther ? typeTextV : titlePkrV?.label && typeTextV, // Use typeTextV when "other" is selected
      };

      // Check if inclusion already exists, including "other" with the same title
      const inclusionExists = inclusionsValues.some(
        (inc) =>
          inc.type.value === newInclusion.type.value &&
          (isOther ? inc.description === typeTextV : true) // Compare the description if "other" is selected
      );
      const exclusionExists = exclusionsValues.some(
        (exc) =>
          exc.type.value === newInclusion.type.value &&
          (isOther ? exc.description === typeTextV : true)
      );

      if (inclusionExists || exclusionExists) {
        setValidationMessage(
          isOther
            ? "This 'other' inclusion with the same title already exists in inclusions or exclusions!"
            : "Inclusion exists already  or is added as an exclusion!"
        );
        return;
      }

      const updatedItems = [...inclusionsValues, newInclusion];
      setInclusionsValue(updatedItems);
      setFieldValue("inclusions", updatedItems);

      // Reset fields after adding
      setSelectedTitleInc({});
      setFieldValue("titlePkr", {});
      setFieldValue("typeText", "");
      setValidationMessage(""); // Clear validation message on successful add
    }
  };

  const handleAddExclusion = () => {
    if (titlePkrExcV?.label?.length > 0) {
      const isOther = titlePkrExcV?.value === "OTHER"; // Assuming 'other' is the value for "other"
      const newExclusion = {
        type: { label: titlePkrExcV?.label, value: titlePkrExcV?.value },
        description: isOther ? typeTextExcV : titlePkrExcV?.label && typeTextExcV, // Use typeTextExcV when "other" is selected
      };

      // Check if exclusion already exists, including "other" with the same title
      const exclusionExists = exclusionsValues.some(
        (exc) =>
          exc.type.value === newExclusion.type.value &&
          (isOther ? exc.description === typeTextExcV : true)
      );
      const inclusionExists = inclusionsValues.some(
        (inc) =>
          inc.type.value === newExclusion.type.value &&
          (isOther ? inc.description === typeTextExcV : true)
      );

      if (exclusionExists || inclusionExists) {
        setValidationMessage(
          isOther
            ? "This 'other' exclusion with the same title already exists in inclusions or exclusions!"
            : "Exclusion exists already or is added as an inclusion!"
        );
        return;
      }

      const updatedItems = [...exclusionsValues, newExclusion];
      setExclusionsValue(updatedItems);
      setFieldValue("exclusions", updatedItems);

      // Reset fields after adding
      setSelectedTitleExc({});
      setFieldValue("titlePkrExc", {});
      setFieldValue("typeTextExc", "");
      setValidationMessage(""); // Clear validation message on successful add
    }
  };

  const handleAdditionalInfoChange = (name, value) => {
    setSelectedAdditionalInfo((prevInfo) => {
      let updatedInfo;

      if (value === "no") {
        // Remove the name from the array
        updatedInfo = prevInfo.filter((info) => info !== name);
      } else if (value === "yes") {
        // Add the name to the array if it's not already there
        updatedInfo = prevInfo.includes(name) ? prevInfo : [...prevInfo, name];
      } else {
        updatedInfo = prevInfo;
      }

      // Set the additionalInfo field in the form
      setFieldValue("additionalInfo", updatedInfo);
      return updatedInfo;
    });
  };

  const languagesList = [
    { code: "en", name: "English", default: false },
    { code: "fr", name: "French", default: false },
    { code: "nl", name: "Dutch", default: false },
    { code: "es", name: "Spanish", default: false },
    { code: "ru", name: "Russian", default: false },
    { code: "ar", name: "Arabic", default: false },
    { code: "it", name: "Italian", default: false },
    { code: "gr", name: "German", default: false },
  ];

  const handleHealthRestrictionChange = (event) => {
    const { value, checked } = event.target;
    if (checked) {
      setRestrictions([...restrictions, value]);
      setFieldValue("healthRestrictions", [...restrictions, value]);
    } else {
      setRestrictions(restrictions.filter((restriction) => restriction !== value));
      setFieldValue(
        "healthRestrictions",
        restrictions.filter((restriction) => restriction !== value)
      );
    }
  };

  const handleCheckboxChange = (language, type) => {
    setLanguageGuides((prev) => {
      const existingLanguage = prev.find((item) => item.language === language);
      let updatedLanguages;

      if (existingLanguage) {
        if (existingLanguage.type === type) {
          // If the type is already selected, remove it
          updatedLanguages = prev.filter(
            (item) => !(item.language === language && item.type === type)
          );
        } else {
          // Update the existing entry with the new type
          updatedLanguages = prev.map((item) =>
            item.language === language ? { ...item, type } : item
          );
        }
      } else {
        // Add a new entry
        updatedLanguages = [...prev, { language, type }];
      }

      // Update setFieldValue
      setFieldValue("languageGuides", updatedLanguages);

      return updatedLanguages;
    });
  };

  const isEmpty = (value) => value === undefined || value === null || value === "";

  const handleAddVenue = () => {
    if (!descriptionV || descriptionV.trim() === "") {
      setValidationMessage("Description is required.");
      return;
    }

    // Updated validation based on selectedDuration
    if (selectedDuration === "specificDuration") {
      if (isEmpty(dayV) || isEmpty(hourV) || isEmpty(minV)) {
        setValidationMessage("Day, Hour, and Minute are required.");
        return;
      }
    } else if (isEmpty(fromV) || isEmpty(toV)) {
      setValidationMessage("From and To are required.");
      return;
    }

    // Clear validation message on successful addition
    setValidationMessage("");

    const newItem = {
      pointOfInterestLocation: {
        pin: { id: selectedPin.value, label: selectedPin?.label },
        attractionId: {
          id: selectedVenue?.value || venuesV?.value,
          label: selectedVenue?.label || venuesV?.label,
        },
      },
      duration:
        selectedDuration === "specificDuration"
          ? {
              day: dayV,
              hour: hourV,
              minute: minV,
            }
          : {
              from: fromV,
              to: toV,
              timeType,
            },
      description: descriptionV,
    };

    const updatedItems = [...itineraryItems, newItem];
    setItineraryItems(updatedItems);
    setFieldValue("itineraryItems", updatedItems);

    // Reset fields after adding

    setTimeType("MINUTE");
    setSelectedPin({});
    setSelectedVenue({});
    setSelectedPickupPoint({});
    setFieldValue("description", "");
    setFieldValue("day", "");
    setFieldValue("hour", "");
    setFieldValue("min", "");
    setFieldValue("from", "");
    setFieldValue("to", "");
    setFieldValue("pin", {});
    setFieldValue("venues", {});
  };

  const handleAddPickup = () => {
    const newPin = {
      pin: { id: values?.pickupPoint?.value, label: values?.pickupPoint?.label },
      pickupType: values?.pickupType?.value,
    };

    // Merge new pin with the existing pins
    const updatedPins = [...(values?.logistics?.travelerPickup?.pins || []), newPin];

    // Update travelerPickup state and Formik values
    // setTravelerPickup(updatedTravelerPickup);
    setFieldValue("logistics.travelerPickup.pins", updatedPins); // Only update pins in Formik state

    // Reset fields after adding
    setSelectedPickupPoint({});
    setFieldValue("pickupPoint", {});
    setFieldValue("pickupType", {});
    setFieldValue("pickupOptionType", "PICKUP_EVERYONE");
    // Optionally reset other fields if needed:
    // setFieldValue("allowCustomTravelerPickup", false);
    // setFieldValue("minutesBeforeDepartureTimeForPickup", "");
    // setFieldValue("additionalPickupInfo", "");
  };

  return (
    <>
      <ShowHideComponentsPicker title="Ticket Details">
        {detailPage && editPage ? (
          <DetailList
            data={{
              itinerary: values?.itinerary,
              ticketDuration: `${
                values?.itinerary?.duration?.day ? values?.itinerary?.duration?.day : 0
              } day(s), ${
                values?.itinerary?.duration?.hour ? values?.itinerary?.duration?.hour : 0
              } hour(s), ${
                values?.itinerary?.duration?.minute ? values?.itinerary?.duration?.minute : 0
              } minute(s)`,
              DoChildrenNeedToBeAccompaniedByAtLeastOneAdultOrSenior:
                values?.bookingRequirements?.requiresAdultForBooking === true ? "yes" : "no",
              minTravelersPerBooking: values?.bookingRequirements?.minTravelersPerBooking,
              maxTravelersPerBooking: values?.bookingRequirements?.maxTravelersPerBooking,
              requiresAdultForBooking: values?.requiresAdultForBooking,
              skipTheLine: values?.itinerary?.skipTheLine,
            }}
          />
        ) : (
          <MDBox mt={1.625}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <GreenSwitch
                        checked={containStopsV || values?.itinerary?.itineraryItems?.length > 0}
                        name={containStops.name}
                        onChange={(event) => setFieldValue("containStops", event.target.checked)}
                      />
                    }
                    label="Stops"
                  />
                </FormGroup>
              </Grid>
              {(containStopsV || values?.itinerary?.itineraryItems?.length > 0) && (
                <Grid item xs={12}>
                  <MDTypography variant="h6" style={{ marginBottom: "5px", marginRight: "0.5rem" }}>
                    Does your ticket offer admission to more than one venue? *
                  </MDTypography>
                  <DynamicRadioGroup
                    name={isOneVenue.name}
                    value={
                      isOneVenueV || (values?.itinerary?.itineraryItems?.length > 1 ? "yes" : "no")
                    }
                    options={venuesOptions}
                    setFieldValue={setFieldValue}
                  />
                  <MDTypography variant="h6" style={{ marginBottom: "5px", marginRight: "0.5rem" }}>
                    What venue does your ticket offer admission to? *
                  </MDTypography>
                  <Typography
                    style={{ wordBreak: "break-word", fontSize: "0.875rem", fontWeight: 400 }}
                  >
                    {pin.label}
                  </Typography>{" "}
                  <Grid item xs={12}>
                    <PinPicker
                      isClearable
                      // pin={pin}
                      handleChange={handlePinChange}
                      setFieldValue={setFieldValue}
                      selectedPin={selectedPin || {}}
                    />
                    {/* {errors?.parent && touched?.parent && <Danger validation={errors?.parent} />} */}
                  </Grid>
                  <Grid item xs={12}>
                    <VenuePicker
                      type={venuesV || values?.venues}
                      isClearable
                      venues={venues}
                      handleChange={handleVenueChange}
                      setFieldValue={setFieldValue}
                      selectedVenue={selectedVenue || {}}
                    />
                    {/* {errors?.parent && touched?.parent && <Danger validation={errors?.city} />} */}
                  </Grid>
                </Grid>
              )}
            </Grid>
            {(containStopsV || values?.itinerary?.itineraryItems?.length > 0) && (
              <Grid item xs={12} mt={2}>
                <DynamicRadioGroup
                  groupLabel={durationRadio.label}
                  name={durationRadio.name}
                  value={selectedDuration}
                  options={durationOptions}
                  setFieldValue={handleDurationChange}
                />
              </Grid>
            )}
            {containStopsV || values?.itinerary?.itineraryItems?.length > 0 ? (
              selectedDuration === "specificDuration" ? (
                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <FormField
                      type={day.type}
                      label={day.label}
                      name={day.name}
                      value={dayV}
                      placeholder={day.placeholder}
                      // error={errors.day && touched.day}
                      success={dayV?.length > 0 && !errors.day}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormField
                      type={hour.type}
                      label={hour.label}
                      name={hour.name}
                      value={hourV}
                      placeholder={hour.placeholder}
                      // error={errors.hour && touched.hour}
                      success={hourV?.length > 0 && !errors.hour}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormField
                      type={min.type}
                      label={min.label}
                      name={min.name}
                      value={minV}
                      placeholder={min.placeholder}
                      // error={errors.min && touched.min}
                      success={minV?.length > 0 && !errors.min}
                    />
                  </Grid>
                </Grid>
              ) : (
                <Grid container spacing={3}>
                  <Grid item xs={4}>
                    <FormField
                      type={from.type}
                      label={from.label}
                      name={from.name}
                      value={fromV}
                      placeholder={from.placeholder}
                      // error={errors.from && touched.from}
                      success={fromV?.length > 0 && !errors.from}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormField
                      type={to.type}
                      label={to.label}
                      name={to.name}
                      value={toV}
                      placeholder={to.placeholder}
                      // error={errors.to && touched.to}
                      success={toV?.length > 0 && !errors.to}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <Select
                      value={timeType}
                      onChange={(e) => setTimeType(e.target.value)}
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value="MINUTE">Minute</MenuItem>
                      <MenuItem value="HOUR">Hour</MenuItem>
                      <MenuItem value="DAY">Day</MenuItem>
                    </Select>
                  </Grid>
                </Grid>
              )
            ) : null}
            {(containStopsV || values?.itinerary?.itineraryItems?.length > 0) && (
              <>
                <Grid item xs={12}>
                  <FormField
                    type={description.type}
                    label={description.label}
                    name={description.name}
                    value={descriptionV}
                    multiline
                    outlined
                    rows={5}
                    placeholder={description.placeholder}
                    // error={errors.description && touched.description}
                    success={descriptionV?.length > 0 && !errors.description}
                  />
                  {validationMessage && <Danger validation={validationMessage} />}
                </Grid>
                <Grid item xs={12} mt={2}>
                  <MDButton
                    key={values?.itineraryItems?.length}
                    variant="contained"
                    color="secondary"
                    onClick={handleAddVenue}
                    disabled={values?.itineraryItems?.length === 1 && isOneVenueV === "no"}
                  >
                    Add
                  </MDButton>
                </Grid>
              </>
            )}

            <Grid item mt={2} xs={12}>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <GreenSwitch
                      checked={
                        skipTheLineV || values?.skipTheLine || values?.itinerary?.skipTheLine
                      }
                      name={skipTheLine.name}
                      onChange={(event) => setFieldValue("skipTheLine", event.target.checked)}
                    />
                  }
                  label="Skip the line"
                />
              </FormGroup>
            </Grid>

            <Divider
              sx={{ height: "1px", width: "100%", backgroundColor: "gray", margin: "30px 0 0 0" }}
            />
            {(containStopsV || values?.itinerary?.itineraryItems?.length > 0) && (
              <Grid container alignItems="end" sx={{ marginBottom: "30px" }}>
                <Grid item xs={12}>
                  {values?.itineraryItems?.length > 0 ||
                  values?.itinerary?.itineraryItems?.length > 0 ? (
                    <AvailabilityDurationPanel
                      setFieldValue={setFieldValue}
                      availabilities={allItineraryItems}
                      setItineraryItems={setItineraryItems}
                    />
                  ) : (
                    <MDBox sx={{ marginTop: "30px" }}>
                      <MDTypography variant="h5">No Availability Found!</MDTypography>
                      {errors?.availabilities && touched?.availabilities && (
                        <Danger validation={errors?.availabilities} />
                      )}
                    </MDBox>
                  )}
                </Grid>
              </Grid>
            )}
            <Grid item xs={12} mt={2}>
              <DynamicRadioGroup
                groupLabel={durationRadio2.label}
                name={durationRadio2.name}
                value={selected2Duration || "specificDuration"}
                options={durationOptions}
                setFieldValue={handleDuration2Change}
              />
            </Grid>
            {selected2Duration === "specificDuration" ? (
              <Grid container spacing={3} mb={3}>
                <Grid item xs={4}>
                  <FormField
                    type={day2.type}
                    label={day2.label}
                    name={day2.name}
                    value={day2V}
                    placeholder={day2.placeholder}
                    // error={errors.day && touched.day}
                    success={day2V?.length > 0 && !errors.day2}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormField
                    type={hour2.type}
                    label={hour2.label}
                    name={hour2.name}
                    value={hour2V}
                    placeholder={hour2.placeholder}
                    // error={errors.hour && touched.hour}
                    success={hour2V?.length > 0 && !errors.hour2}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormField
                    type={min2.type}
                    label={min2.label}
                    name={min2.name}
                    value={min2V}
                    placeholder={min2.placeholder}
                    // error={errors.min2 && touched.min2}
                    success={min2V?.length > 0 && !errors.min2}
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid container spacing={3}>
                <Grid item xs={4}>
                  <FormField
                    type={from2.type}
                    label={from2.label}
                    name={from2.name}
                    value={from2V}
                    placeholder={from.placeholder}
                    // error={errors.from && touched.from}
                    success={from2V?.length > 0 && !errors.from2}
                  />
                </Grid>
                <Grid item xs={4}>
                  <FormField
                    type={to2.type}
                    label={to2.label}
                    name={to2.name}
                    value={to2V}
                    placeholder={to2.placeholder}
                    // error={errors.to && touched.to}
                    success={toV?.length > 0 && !errors.to}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Select
                    value={timeType2}
                    onChange={(e) => {
                      setTimeType2(e.target.value);
                      setFieldValue("timeType2", timeType2);
                    }}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    <MenuItem value="MINUTE">Minute</MenuItem>
                    <MenuItem value="HOUR">Hour</MenuItem>
                    <MenuItem value="DAY">Day</MenuItem>
                  </Select>
                </Grid>
              </Grid>
            )}
            <Grid item xs={12}>
              <DynamicRadioGroup
                groupLabel={accompanied.label}
                name={accompanied.name}
                value={accompaniedV || values?.bookingRequirements?.requiresAdultForBooking}
                options={accompaniedOptions}
                setFieldValue={setFieldValue}
              />
            </Grid>
            <Grid container xs={12} spacing={3}>
              <Grid item xs={5}>
                <FormField
                  type={minTravelersPerBooking.type}
                  label={minTravelersPerBooking.label}
                  name={minTravelersPerBooking.name}
                  value={
                    minTravelersPerBookingV || values?.bookingRequirements?.minTravelersPerBooking
                  }
                  placeholder={minTravelersPerBooking.placeholder}
                  error={errors.minTravelersPerBooking && touched.minTravelersPerBooking}
                  success={minTravelersPerBookingV?.length > 0 && !errors.minTravelersPerBooking}
                />
              </Grid>
              <Grid item xs={5}>
                <FormField
                  type={maxTravelersPerBooking.type}
                  label={maxTravelersPerBooking.label}
                  name={maxTravelersPerBooking.name}
                  value={
                    maxTravelersPerBookingV || values?.bookingRequirements?.maxTravelersPerBooking
                  }
                  placeholder={maxTravelersPerBooking.placeholder}
                  error={errors.maxTravelersPerBooking && touched.maxTravelersPerBooking}
                  success={maxTravelersPerBookingV?.length > 0 && !errors.maxTravelersPerBooking}
                />
              </Grid>
            </Grid>
          </MDBox>
        )}
      </ShowHideComponentsPicker>
      <ShowHideComponentsPicker title="Languages">
        {" "}
        {detailPage && editPage ? (
          <DetailList
            data={{
              languageGuides: values?.languageGuides,
            }}
          />
        ) : (
          <MDBox mt={1.625}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <LanguagesPicker
                  isObj
                  languagesList={languagesList}
                  languages={languages}
                  languagesV={
                    Array.isArray(languagesV) && languagesV.length > 0
                      ? languagesV
                      : values?.languageGuides
                  }
                  setFieldValue={setFieldValue}
                  // error={errors.languages && touched.languages}
                  success={languagesV && !errors.languages}
                />
              </Grid>
              {values?.languages && languagesV?.length > 0 && (
                <Grid item xs={12}>
                  <Typography variant="h6">Languages</Typography>
                  <Grid container spacing={2} mt={2}>
                    <Grid item xs={3}>
                      <Typography fontSize="medium">Languages</Typography>
                    </Grid>
                    <Grid item xs={2} display="flex" alignItems="center">
                      <PersonOutlineIcon style={{ marginRight: "8px" }} />
                      <Typography fontSize="medium">In-person</Typography>
                    </Grid>
                    <Grid item xs={2} display="flex" alignItems="center">
                      <HeadsetIcon style={{ marginRight: "8px" }} />
                      <Typography fontSize="medium">Audio</Typography>
                    </Grid>
                    <Grid item xs={2} display="flex" alignItems="center">
                      <LibraryBooksIcon style={{ marginRight: "8px" }} />
                      <Typography fontSize="medium">Written</Typography>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>

            {languagesV.map((language) => (
              <Grid container spacing={2} alignItems="center" key={language?.name}>
                <Grid item xs={3}>
                  <Typography fontSize="medium">{language?.code}</Typography>
                </Grid>
                <Grid item xs={2}>
                  <Checkbox
                    checked={languageGuides.some(
                      (item) => item.language === language?.code && item.type === "GUIDE"
                    )}
                    onChange={() => handleCheckboxChange(language?.code, "GUIDE")}
                  />
                </Grid>
                <Grid item xs={2}>
                  <Checkbox
                    checked={languageGuides.some(
                      (item) => item.language === language?.code && item.type === "AUDIO"
                    )}
                    onChange={() => handleCheckboxChange(language?.code, "AUDIO")}
                  />
                </Grid>
                <Grid item xs={2} display="flex" alignItems="center">
                  <Checkbox
                    checked={languageGuides.some(
                      (item) => item.language === language?.code && item.type === "WRITTEN"
                    )}
                    onChange={() => handleCheckboxChange(language?.code, "WRITTEN")}
                  />
                </Grid>
                <Grid item xs={3} display="flex" alignItems="center">
                  <IconButton onClick={() => handleRemoveLanguage(language)}>
                    <ClearIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ))}
          </MDBox>
        )}
      </ShowHideComponentsPicker>
      <ShowHideComponentsPicker title="Inclusions & Exclusions">
        {" "}
        {detailPage && editPage ? (
          <DetailList
            data={{
              exclusions: values?.exclusions,
              inclusions: values?.inclusions,
            }}
          />
        ) : (
          <MDBox mt={1.625}>
            <Grid container xs={12} spacing={2}>
              <Grid item xs={6} mb={2}>
                <TitlePicker
                  titlePkr={titlePkr}
                  setFieldValue={setFieldValue}
                  title={selectedTitleInc}
                  handleTitleChange={handleTitleIncChange}
                />
              </Grid>
              {values?.titlePkr?.label === "Other" && (
                <Grid item xs={6}>
                  <FormField
                    type={typeText.type}
                    label={typeText.label}
                    name={typeText.name}
                    value={values?.typeText}
                    placeholder={typeText.placeholder}
                    error={errors.typeText && touched.typeText}
                    success={values?.typeText?.length > 0 && !errors.typeText}
                  />
                </Grid>
              )}
            </Grid>
            <Grid item xs={12} mb={2}>
              <MDButton
                key={values?.inclusions?.length}
                variant="contained"
                color="secondary"
                onClick={() => {
                  handleAddInclusion();
                }}
              >
                Add Inclusions
              </MDButton>
            </Grid>
            {validationMessage &&
              (validationMessage[0] === "I" || validationMessage[13] === "i") && (
                <Danger validation={validationMessage} />
              )}
            <Divider
              sx={{ height: "1px", width: "100%", backgroundColor: "gray", margin: "20px 0 0 0" }}
            />
            <Grid container alignItems="end" sx={{ marginBottom: "30px" }}>
              <Grid item xs={12}>
                {values?.inclusions?.length > 0 ? (
                  <AvailabilityInclusionPanel
                    setFieldValue={setFieldValue}
                    availabilities={values?.inclusions}
                  />
                ) : (
                  <MDBox sx={{ marginTop: "30px" }}>
                    <MDTypography variant="h5">No Inclusions Found!</MDTypography>
                    {errors?.availabilities && touched?.availabilities && (
                      <Danger validation={errors?.availabilities} />
                    )}
                  </MDBox>
                )}
              </Grid>
            </Grid>
            <Grid container xs={12} spacing={2}>
              <Grid item xs={6}>
                <TitlePicker
                  isExclusion
                  titlePkr={titlePkrExc}
                  setFieldValue={setFieldValue}
                  title={selectedTitleExc}
                  handleTitleChange={handleTitleExcChange}
                />
              </Grid>
              <Grid item xs={6}>
                {values?.titlePkrExc?.label === "Other" && (
                  <FormField
                    type={typeTextExc.type}
                    label={typeTextExc.label}
                    name={typeTextExc.name}
                    value={values?.typeTextExc}
                    placeholder={typeTextExc.placeholder}
                    error={errors.typeTextExc && touched.typeTextExc}
                    success={values?.typeTextExc?.length > 0 && !errors.typeTextExc}
                  />
                )}
              </Grid>
            </Grid>
            <Grid item xs={12} mb={2} mt={2}>
              <MDButton
                key={values?.inclusions?.length}
                variant="contained"
                color="secondary"
                onClick={() => {
                  handleAddExclusion();
                }}
              >
                Add Exclusions
              </MDButton>
            </Grid>
            {validationMessage &&
              (validationMessage[0] === "E" || validationMessage[13] === "e") && (
                <Danger validation={validationMessage} />
              )}
            <Divider
              sx={{ height: "1px", width: "100%", backgroundColor: "gray", margin: "20px 0 0 0" }}
            />
            <Grid container alignItems="end" sx={{ marginBottom: "30px" }}>
              <Grid item xs={12}>
                {values?.exclusions?.length > 0 ? (
                  <AvailabilityExclusionPanel
                    setFieldValue={setFieldValue}
                    availabilities={values?.exclusions}
                  />
                ) : (
                  <MDBox sx={{ marginTop: "30px" }}>
                    <MDTypography variant="h5">No Exclusions Found!</MDTypography>
                    {errors?.availabilities && touched?.availabilities && (
                      <Danger validation={errors?.availabilities} />
                    )}
                  </MDBox>
                )}
              </Grid>
            </Grid>
          </MDBox>
        )}
      </ShowHideComponentsPicker>
      <ShowHideComponentsPicker title="Meeting & pickup points">
        {detailPage && editPage ? (
          <DetailList
            data={{
              logistics: values?.logistics,
              TravellersDefineTheirPickupPoints:
                values?.logistics?.travelerPickup?.allowCustomTravelerPickup,
              additionalPickupInfo: values?.logistics?.travelerPickup?.additionalInfo,
              PickupBefore: `${
                values?.logistics?.travelerPickup?.minutesBeforeDepartureTimeForPickup || 0
              } minutes`,
            }}
          />
        ) : (
          <MDBox mt={1.625}>
            <Grid item xs={12}>
              <PinsPicker
                pins={pinsStart}
                pinsV={pinsStartV || startsPins}
                fieldName="pinsStart"
                setFieldValue={setFieldValue}
                error={errors.pinsStartV && touched.pinsStartV}
                success={values?.pinsStart?.length > 0 && !errors.pinsStart}
                // selectedTag={selectedTag}
              />
            </Grid>{" "}
            <Grid item xs={12}>
              <PinsPicker
                pins={pinsEnd}
                pinsV={pinsEndV || endsPins}
                fieldName="pinsEnd"
                setFieldValue={setFieldValue}
                error={errors.pinsEndV && touched.pinsEndV}
                success={values?.pinsEnd?.length > 0 && !errors.pinsEnd}
                // selectedTag={selectedTag}
              />
            </Grid>{" "}
            <MDTypography variant="h6" style={{ marginTop: "5px", marginRight: "0.5rem" }}>
              Pickup information:
            </MDTypography>
            <Grid item xs={12}>
              <DynamicRadioGroup
                value={pickupOptionTypeV}
                options={pickupOptions}
                name={pickupOptionType.name}
                setFieldValue={setFieldValue}
                groupLabel="Pickup Option type:"
                disabled={false}
              />
            </Grid>
            {(pickupOptionTypeV === "PICKUP_EVERYONE" ||
              values?.logistics?.travelerPickup?.pickupOptionType === "PICKUP_EVERYONE" ||
              values?.logistics?.travelerPickup?.pickupOptionType ===
                "PICKUP_AND_MEET_AT_START_POINT" ||
              pickupOptionTypeV === "PICKUP_AND_MEET_AT_START_POINT") && (
              <>
                <Grid container xs={12}>
                  <Grid item xs={12}>
                    {" "}
                    <Typography
                      style={{ wordBreak: "break-word", fontSize: "0.875rem", fontWeight: 400 }}
                    >
                      {pickupPoint.label}
                    </Typography>
                    <PinPicker
                      isClearable
                      // pin={pin}
                      isPickupPoint
                      handleChange={handlePickupPointChange}
                      setFieldValue={setFieldValue}
                      selectedPin={selectedPickupPoint || {}}
                    />
                    {/* {errors?.parent && touched?.parent && <Danger validation={errors?.parent} />} */}
                  </Grid>
                  <Grid item xs={12} mt={3}>
                    <PickupTypePicker
                      pickupType={pickupType}
                      pickupTypeV={values?.pickupType || {}}
                      setFieldValue={setFieldValue}
                      // error={errors.pickupType && touched.pickupType}
                      success={values?.pickupType && !errors.pickupType}
                    />
                  </Grid>
                </Grid>{" "}
              </>
            )}{" "}
            {pickupOptionTypeV !== "MEET_EVERYONE_AT_START_POINT" && (
              <>
                <Grid item xs={12} mt={2}>
                  <MDButton
                    key={values?.travelerPickup?.length}
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      if (values?.pickupType?.label || values?.pickupPoint?.label)
                        handleAddPickup();
                    }}
                  >
                    Add
                  </MDButton>
                </Grid>
                <Divider
                  sx={{
                    height: "1px",
                    width: "100%",
                    backgroundColor: "gray",
                    margin: "30px 0 0 0",
                  }}
                />
                <Grid container alignItems="end" sx={{ marginBottom: "30px" }}>
                  <Grid item xs={12}>
                    {values?.travelerPickup?.pins?.length > 0 ||
                    values?.logistics?.travelerPickup?.pins?.length > 0 ? (
                      <AvailabilityPickupPanel
                        setFieldValue={setFieldValue}
                        // availabilities={allTravelerPickups}
                        availabilities={values?.travelerPickup || values?.logistics?.travelerPickup}
                      />
                    ) : (
                      <MDBox sx={{ marginTop: "30px" }}>
                        <MDTypography variant="h5">No Availability Found!</MDTypography>
                        {errors?.availabilities && touched?.availabilities && (
                          <Danger validation={errors?.availabilities} />
                        )}
                      </MDBox>
                    )}
                  </Grid>
                </Grid>
              </>
            )}
            <Grid item xs={12}>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <GreenSwitch
                      checked={allowCustomTravelerPickupV}
                      name={allowCustomTravelerPickup.name}
                      onChange={(event) => {
                        setFieldValue("allowCustomTravelerPickup", event.target.checked);
                        setFieldValue(
                          "logistics.travelerPickup.allowCustomTravelerPickup",
                          event.target.checked
                        );
                      }}
                    />
                  }
                  label="Allow travellers to define their pickup points."
                />
              </FormGroup>
            </Grid>
            <Grid item xs={12} style={{ marginTop: "10px" }}>
              <FormField
                // type={additionalPickupInfo.type}
                label={additionalPickupInfo.label}
                name={additionalPickupInfo.name}
                value={additionalPickupInfoV}
                multiline
                outlined
                rows={5}
                placeholder={additionalPickupInfo.placeholder}
                // error={errors.additionalPickupInfo && touched.additionalPickupInfo}
                success={additionalPickupInfoV?.length > 0 && !errors?.additionalPickupInfo}
              />
            </Grid>
            <Grid item xs={6}>
              <FormField
                type={minutesBeforeDepartureTimeForPickup.type}
                label={minutesBeforeDepartureTimeForPickup.label}
                name={minutesBeforeDepartureTimeForPickup.name}
                value={minutesBeforeDepartureTimeForPickupV}
                placeholder={minutesBeforeDepartureTimeForPickup.placeholder}
                success={
                  minutesBeforeDepartureTimeForPickupV?.length > 0 &&
                  !errors.minutesBeforeDepartureTimeForPickup
                }
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <span style={{ fontSize: "0.85rem" }}>minutes</span>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </MDBox>
        )}
      </ShowHideComponentsPicker>
      <ShowHideComponentsPicker title="Additional information">
        {detailPage && editPage ? (
          <DetailList
            data={{
              additionalInfo: values?.additionalInfo,
            }}
          />
        ) : (
          <MDBox mt={1.625}>
            <Grid item xs={12}>
              <div style={{ display: "flex", flexDirection: "column", marginBottom: "15px" }}>
                <MDTypography variant="h6">Add any Physical restrictions</MDTypography>
                <MDTypography variant="regular" style={{ fontSize: "small" }}>
                  This information will help travelers know if this is a good ticket/pass for them
                </MDTypography>
              </div>

              <AccessibilityForm
                selectedAdditionalInfo={selectedAdditionalInfo}
                handleAccessibilityChange={handleAdditionalInfoChange}
                setFieldValue={setFieldValue}
                physicalRestrictionsV={values?.additionalInfo || values?.physicalRestrictions}
              />
            </Grid>
            <Grid item xs={12} mt={3}>
              <div>
                <FormLabel component="legend">Health restrictions </FormLabel>
                <FormLabel component="legend" style={{ fontSize: "12px" }}>
                  Check all that apply
                </FormLabel>
                <FormGroup>
                  {healthRestrictions.map((restriction) => (
                    <FormControlLabel
                      key={restriction.value}
                      control={
                        <Checkbox
                          checked={
                            values?.healthRestrictions?.includes(restriction.value) ||
                            restrictions.includes(restriction.value)
                          }
                          onChange={handleHealthRestrictionChange}
                          value={restriction.value}
                        />
                      }
                      label={restriction.label}
                    />
                  ))}
                </FormGroup>
              </div>
            </Grid>
            <Grid item xs={12} mt={3}>
              <FormLabel component="legend">Select the physical difficulty level</FormLabel>
              <DynamicRadioGroup
                isColumn
                value={physicalDifficultyLevelV}
                options={radioOptions}
                name="physicalDifficultyLevel"
                // handleChange={handleRadioChange}
                setFieldValue={setFieldValue}
                // groupLabel="Select the physical difficulty level"
                disabled={false}
                helperTextStyle={helperTextStyle}
              />
            </Grid>
          </MDBox>
        )}
      </ShowHideComponentsPicker>
      <ShowHideComponentsPicker title="Badges">
        {" "}
        {detailPage && editPage ? (
          <DetailList
            data={{
              badges: getBadgeLabel(values?.badges),
              highlight: values?.highlight,
              top: values?.top,
            }}
          />
        ) : (
          <MDBox mt={1.625}>
            <Grid item xs={6}>
              <BadgePicker
                badge={badge}
                badgeV={
                  Array.isArray(values?.badges) && values.badges.length > 0
                    ? values?.badges?.[0]
                    : values?.badges
                }
                setFieldValue={setFieldValue}
                // error={errors.badge && touched.badge}
                success={values?.badge && !errors.badge}
              />
            </Grid>
            <Grid item xs={12}>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <GreenSwitch
                      checked={topV}
                      name={top.name}
                      onChange={(event) => setFieldValue("top", event.target.checked)}
                    />
                  }
                  label="Top"
                />
                <FormControlLabel
                  control={
                    <GreenSwitch
                      checked={highlightV}
                      name={highlight.name}
                      onChange={(event) => setFieldValue("highlight", event.target.checked)}
                    />
                  }
                  label="Highlight"
                />
              </FormGroup>
            </Grid>
          </MDBox>
        )}
      </ShowHideComponentsPicker>
    </>
  );
};

Content.defaultProps = {
  editPage: false,
  detailPage: true,
  handleVenueChange: () => {},
  setSelectedPin: () => {},
  setSelectedVenue: () => {},
  selectedVenue: {},
  selectedPin: {},
  selectedPickupPoint: {},
  setSelectedPickupPoint: () => {},
  setSelectedTitleExc: () => {},
  setSelectedTitleInc: () => {},
  selectedTitleInc: {},
  selectedTitleExc: {},
  handleTitleIncChange: () => {},
  handleTitleExcChange: () => {},
  handlePinChange: () => {},
  handlePickupPointChange: () => {},
};

Content.propTypes = {
  formData: PropTypes.oneOfType([PropTypes.instanceOf(Object), PropTypes.func]).isRequired,
  editPage: PropTypes.bool,
  handleVenueChange: PropTypes.func,
  handlePinChange: PropTypes.func,
  handlePickupPointChange: PropTypes.func,
  handleTitleIncChange: PropTypes.func,
  handleTitleExcChange: PropTypes.func,
  selectedTitleInc: PropTypes.instanceOf(Object),
  selectedTitleExc: PropTypes.instanceOf(Object),
  selectedPin: PropTypes.instanceOf(Object),
  setSelectedPin: PropTypes.func,
  setSelectedVenue: PropTypes.func,
  selectedPickupPoint: PropTypes.instanceOf(Object),
  setSelectedPickupPoint: PropTypes.func,
  setSelectedTitleExc: PropTypes.func,
  setSelectedTitleInc: PropTypes.func,
  selectedVenue: PropTypes.instanceOf(Object),
  detailPage: PropTypes.bool,
};

export default Content;
