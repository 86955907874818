/* eslint-disable no-case-declarations */
/* eslint-disable no-underscore-dangle */
import React from "react";
import PropTypes from "prop-types";
import AsyncSelect from "react-select";
import serviceJobsOptions from "./serviceJobsOption";

const ServiceJobsCategoriesPicker = ({ setFieldValue, serviceJobsObjV }) => {
  const handleChange = (selectedOption) => {
    if (!selectedOption) {
      setFieldValue("serviceJobsObj", null);
      return;
    }

    // Find the parent category label based on the selected option
    const selectedCategory = serviceJobsOptions.find((category) =>
      category.options.some((option) => option.value === selectedOption.value)
    );

    const categoryLabel = selectedCategory ? selectedCategory.label : null;

    setFieldValue("serviceJobsObj", {
      category: categoryLabel,
      subcategory: selectedOption?.label,
      categoryId: selectedOption?.id,
    });
  };

  return (
    <div>
      <AsyncSelect
        options={serviceJobsOptions}
        onChange={handleChange}
        value={
          serviceJobsObjV
            ? {
                label: serviceJobsObjV?.subcategory,
                value: serviceJobsObjV?.categoryId,
              }
            : null
        }
        placeholder="Select a Category"
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
        styles={{
          control: (base, state) => ({
            ...base,
            minHeight: "40px",
            borderColor: state.isFocused ? "#003005" : base.borderColor,
            boxShadow: state.isFocused ? "0 0 0 1px #003005" : base.boxShadow,
            "&:hover": {
              borderColor: state.isFocused ? "#003005" : base.borderColor,
            },
          }),
          input: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
          menuList: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
          placeholder: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
          singleValue: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
        }}
      />
    </div>
  );
};

// Setting default props
ServiceJobsCategoriesPicker.defaultProps = {
  serviceJobsObjV: {},
};

// Prop-types for ServiceJobsCategoriesPicker
ServiceJobsCategoriesPicker.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  serviceJobsObjV: PropTypes.instanceOf(Object),
};

export default ServiceJobsCategoriesPicker;
