import checkout from "./form";

const {
  formField: {
    // location page
    name,
    partner,
    email,
    top,
    logo,
    primary,
    background,
    button,
    // address
    address,
    city,
    region,
    country,
    lat,
    lng,
    primaryDestination,
    destinations,
    // additional location info
    travelDesk,
    numRooms,
    dinningLabel,
    serviceTitle,
    images,
    disclaimer,
    disclaimerText,
    template,
    enableServices,
    trendSubTitle,
    trendTitle,
    // sliders
    showSlider,
    showGuestCompanion,
    slideDuration,
    slider,
    serviceJobs,
    serviceJobsObj,
    // guest companion
    jobName,
    jobCode,
    propertyCode,
    company,
    system,
    version,
  },
} = checkout;

const initialValues = {
  // user page
  [name.name]: "",
  [partner.name]: "",
  [email.name]: [],
  [top.name]: false,
  [logo.name]: "",
  [primary.name]: "#00acc1",
  [background.name]: "#ccc9c9",
  [button.name]: "#efebeb",
  // user address
  [address.name]: "",
  [city.name]: "",
  [region.name]: "",
  [country.name]: "",
  [lat.name]: "",
  [lng.name]: "",
  [primaryDestination.name]: "",
  [destinations.name]: [],
  // additional partner info
  [travelDesk.name]: "",
  [numRooms.name]: 0,
  [disclaimer.name]: false,
  [disclaimerText.name]: {},
  [enableServices.name]: false,
  [dinningLabel.name]: "",
  [serviceTitle.name]: "",
  [template.name]: "default",
  [images.name]: [],
  [trendSubTitle.name]: "",
  [trendTitle.name]: "",
  // slider
  [showSlider.name]: false,
  [showGuestCompanion.name]: false,
  [slideDuration.name]: 7000,
  [slider.name]: [],
  [serviceJobs.name]: [],
  [serviceJobsObj.name]: [],
  // guest companion
  [jobName.name]: "",
  [jobCode.name]: "",
  [propertyCode.name]: "",
  [company.name]: "",
  [system.name]: "",
  [version.name]: "",
};

export default initialValues;
