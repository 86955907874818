const serviceJobsOptions = [
  {
    label: "Maintenance",
    options: [
      { label: "Guest - Engineering", value: "guest-engineering-2", id: 2 },
      { label: "Guest - Information Technology", value: "guest-it-5", id: 5 },
      { label: "Internal - Engineering", value: "internal-engineering-9", id: 9 },
      { label: "Information Technology", value: "it-12", id: 12 },
      { label: "Complaint - Engineering", value: "complaint-engineering-16", id: 16 },
      { label: "Complaint - Information Technology", value: "complaint-it-19", id: 19 },
    ],
  },
  {
    label: "Housekeeping",
    options: [
      { label: "Guest - Room Service", value: "guest-room-service-7", id: 7 },
      { label: "Internal - Housekeeping", value: "internal-housekeeping-11", id: 11 },
      { label: "Internal - Room Service", value: "internal-room-service-14", id: 14 },
      { label: "Complaint - Housekeeping", value: "complaint-housekeeping-18", id: 18 },
      { label: "Complaint - Room Service", value: "complaint-room-service-21", id: 21 },
    ],
  },
];

export default serviceJobsOptions;
