/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-underscore-dangle */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import AsyncSelect from "react-select/async";
import debounce from "lodash/debounce";

import { Typography } from "@mui/material";

const TagsPicker = ({ tags, tagsV, handleChange, setFieldValue, type }) => {
  const filterRec = (data) =>
    data.map((tagData) => ({
      label: tagData.title ? tagData.title : tagData.label,
      value: tagData._id ? tagData._id : tagData.value,
    }));

  const promiseOptions = (inputValue) =>
    new Promise((resolve) => {
      let url = `${process.env.REACT_APP_API}/tags?fields=_id,title${type ? `&type=${type}` : ""}`;
      url += `${inputValue && `&title=${inputValue}`}`;
      url += "&sort=title";
      setTimeout(() => {
        fetch(url)
          .then((response) => response.json())
          .then((result) => {
            const filteredData = filterRec(result.data);
            // Sort the filtered data, placing special characters at the bottom
            const sortedData = filteredData.sort((a, b) => {
              const isSpecialCharA = /[^a-zA-Z0-9]/.test(a.label[0]);
              const isSpecialCharB = /[^a-zA-Z0-9]/.test(b.label[0]);
              if (isSpecialCharA && !isSpecialCharB) return 1;
              if (!isSpecialCharA && isSpecialCharB) return -1;
              return a.label.localeCompare(b.label);
            });
            resolve(sortedData);
          })
          .catch((error) => console.log(error.message));
      }, 1000);
    });

  useEffect(() => {
    promiseOptions();
  }, []);

  const debouncedLoadOptions = debounce((inputValue, callback) => {
    promiseOptions(inputValue)
      .then((options) => {
        callback(options);
      })
      .catch((error) => {
        console.error(error);
      });
  }, 1000); // Adjust the delay time (in milliseconds) as needed

  const handleChangeWrapper = (options) => {
    if (!options || options.length === 0) {
      setFieldValue("tags", []);
      handleChange(options);
    } else {
      const formattedOptions = options.map((option) => ({
        value: option._id || option.value,
        label: option.title || option.label,
      }));

      setFieldValue("tags", formattedOptions);
      handleChange(formattedOptions);
    }
  };

  return (
    <div>
      <Typography
        style={{
          wordBreak: "break-word",
          fontSize: "0.875rem",
          fontWeight: 400,
        }}
      >
        {tags.label}
      </Typography>{" "}
      <AsyncSelect
        isMulti
        cacheOptions
        defaultOptions
        value={tagsV?.map((tag) => ({
          label: tag.title || tag.label,
          value: tag._id || tag.value,
        }))}
        placeholder="Search for a Tag"
        menuPosition="fixed"
        loadOptions={debouncedLoadOptions}
        onChange={handleChangeWrapper}
        components={{
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
          LoadingIndicator: () => null,
        }}
        styles={{
          control: (base, state) => ({
            ...base,
            minHeight: "40px",
            borderColor: state.isFocused ? "#003005" : base.borderColor, // Change border color on focus
            boxShadow: state.isFocused ? "0 0 0 1px #003005" : base.boxShadow, // Optional: Add a shadow for better visual
            "&:hover": {
              borderColor: state.isFocused ? "#003005" : base.borderColor, // Maintain border color on hover
            },
          }),
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
          }),
          input: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
          menuList: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
          placeholder: (base) => ({
            ...base,
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            overflow: "hidden",
            maxWidth: "100%",
            WebkitLineClamp: 1,
            fontSize: "0.875rem",
          }),
          multiValue: (base) => ({
            ...base,
            fontSize: "0.875rem",
          }),
        }}
      />
    </div>
  );
};

TagsPicker.defaultProps = {
  tagsV: [],
  handleChange: () => {},
  tags: {},
  type: "",
};

TagsPicker.propTypes = {
  tags: PropTypes.instanceOf(Object),
  handleChange: PropTypes.func,
  setFieldValue: PropTypes.func.isRequired,
  tagsV: PropTypes.instanceOf(Array),
  type: PropTypes.string,
};

export default TagsPicker;
