const formCompanion = {
  formId: "companion-location-form",
  formField: {
    jobName: {
      name: "jobName",
      label: "Job Name",
      type: "text",
    },
    jobCode: {
      name: "jobCode",
      label: "Job Code",
      type: "text",
    },
    propertyCode: {
      name: "propertyCode",
      label: "Property Code",
      type: "text",
    },
    inRoomDiningPropertyId: {
      name: "inRoomDiningPropertyId",
      label: "Property ID",
      type: "text",
    },
    company: {
      name: "company",
      label: "Company",
      type: "text",
    },
    system: {
      name: "system",
      label: "System",
      type: "text",
    },
    version: {
      name: "version",
      label: "Version",
      type: "text",
    },
  },
};

export default formCompanion;
