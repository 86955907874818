/* eslint-disable no-console */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */

import React, { createRef, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Divider, Grid, Tabs, Tab, Switch, Typography, Card, CardContent } from "@mui/material";
import MDBox from "components/MDBox";
import MaterialTablePicker from "components/MaterialTablePicker";
import CopytoClipboard from "components/CopytoClipboard";
import ViewEditActions from "components/DetailList/ViewEditActions";
import FormField from "components/FormField";
import MDButton from "components/MDButtonLoading";
import ServiceJobsCompanyPicker from "components/AutoComplete/ٍServiceJobs/ٍServiceJobsCompanyPicker";
import ServiceJobsSystemPicker from "components/AutoComplete/ٍServiceJobs/ServiceJobsSystemPicker";
import ServiceJobsVersionPicker from "components/AutoComplete/ٍServiceJobs/ServiceJobsVersionPicker";
import ServiceJobsCategoriesPicker from "components/AutoComplete/ٍServiceJobs/ServiceJobsCategoriesPicker";
import DetailList from "components/DetailList";
import Danger from "components/Danger";
import { addJobs } from "store/actions/locationAction";
import { getUpdatedFilter } from "store/actions/userActions";
import { Formik, Form } from "formik";
import validationsCompanion from "../schemas/validationsCompanion";
import initialValuesCompanion from "../schemas/initialValuesCompanion";

const GuestCompanion = ({ location }) => {
  const dispatch = useDispatch();
  const tableRef = createRef();
  const url =
    process.env.REACT_APP_ENV === "production"
      ? "https://lokalee.app"
      : "https://staging.lokalee.app";

  const [selectedTab, setSelectedTab] = useState(0);
  const [jobError, setJobError] = useState(false);
  const [initialValues, setInitialValues] = useState(initialValuesCompanion);
  const [detailPage, setDetailPage] = useState(true);

  const reduxFilters = useSelector((state) => state.users.filters);

  useEffect(() => {
    dispatch(
      getUpdatedFilter({
        filters: reduxFilters?.filters,
        row_nb: reduxFilters?.row_nb,
        pageValue: reduxFilters?.pageValue,
        search: "",
      })
    );
  }, []);

  useEffect(() => {
    if (location) {
      const manipulated = {};
      manipulated.company = location.guestRequestType || "";
      manipulated.propertyCode = location.guestRequestPropertyId || "";
      manipulated.inRoomDiningPropertyId = location.inRoomDiningPropertyId || "";
      manipulated.inRoomDining = location.inRoomDining || false;
      manipulated.serviceJobs = location.serviceJobs || [];
      manipulated.system = location.guestRequestType === "sky" ? "guestapp" : "servicerequest";
      manipulated.version = location.guestRequestType === "sky" ? "v1" : "v0.1";
      manipulated.title = "";
      manipulated.code = "";
      manipulated.serviceJobsObj = null;
      manipulated.showGuestCompanion = location.showGuestCompanion || false;
      setInitialValues(manipulated);
    }
  }, [location]);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  const handleAddJob = (values, setFieldValue) => {
    if (!values.title || !values.code || !values.serviceJobsObj) {
      setJobError(true);
      return;
    }
    setJobError(false);
    const jobs = values.serviceJobs || [];
    const newJob = {
      title: values.title,
      code: values.code,
      category: values.serviceJobsObj?.category?.toLowerCase(),
      externalCategoryId: values.serviceJobsObj?.categoryId,
      subcategory: values.serviceJobsObj?.subcategory,
    };
    jobs.push(newJob);
    setFieldValue("serviceJobs", jobs);
    setFieldValue("title", "");
    setFieldValue("code", "");
    setFieldValue("serviceJobsObj", null);
  };

  const handleDeleteJob = (index, values, setFieldValue) => {
    const jobs = values.serviceJobs || [];
    jobs.splice(index, 1);
    setFieldValue("serviceJobs", jobs);
  };

  return (
    <MDBox>
      <Grid container justifyContent="center">
        <Grid item xs={12} md={12} lg={12}>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationsCompanion[0]}
          >
            {({ values, errors, touched, setFieldValue, resetForm }) => (
              <Form autoComplete="off">
                <Card>
                  <CardContent>
                    <MDBox>
                      <Grid
                        container
                        alignItems="center"
                        justifyContent="space-between"
                        sx={{ mb: 2 }}
                      >
                        <Grid item />
                        <Grid item>
                          <ViewEditActions
                            detailPage={detailPage}
                            stateName="locations"
                            handleEdit={() => {
                              setDetailPage(false);
                            }}
                            handleClose={() => {
                              setDetailPage(true);
                              resetForm();
                              setFieldValue("serviceJobs", location.serviceJobs);
                            }}
                            handleSave={() => {
                              dispatch(addJobs(location?._id, values, selectedTab));
                              setDetailPage(true);
                            }}
                          />
                        </Grid>
                      </Grid>
                      <Tabs
                        value={selectedTab}
                        onChange={handleTabChange}
                        aria-label="guest companion tabs"
                      >
                        <Tab label="Guest Request Management" />
                        <Tab label="In-Room Dining" />
                      </Tabs>
                      <Divider sx={{ marginY: 2 }} />
                      {detailPage && selectedTab === 0 && (
                        <DetailList
                          data={{
                            company: values?.company,
                            propertyCode: values?.propertyCode,
                            showGuestCompanion: values?.showGuestCompanion,
                          }}
                        />
                      )}
                      {detailPage && selectedTab === 1 && (
                        <DetailList
                          data={{
                            inRoomDiningPropertyId: values?.inRoomDiningPropertyId,
                          }}
                        />
                      )}
                      {!detailPage && selectedTab === 0 && (
                        <>
                          <Typography
                            variant="h6"
                            component="div"
                            sx={{ display: "inline", mr: 2 }}
                          >
                            Guest Companion
                          </Typography>
                          <Switch
                            checked={values?.showGuestCompanion}
                            onChange={() =>
                              setFieldValue("showGuestCompanion", !values?.showGuestCompanion)
                            }
                            color="primary"
                          />

                          {values?.showGuestCompanion && (
                            <Grid container spacing={2} alignItems="center" sx={{ mt: 1 }}>
                              <Grid item xs={12} mb={4}>
                                <CopytoClipboard
                                  text={`${url}/locations/${location?.slug}/companion`}
                                />
                              </Grid>
                              <Typography style={{ fontSize: "0.875rem", fontWeight: 400 }}>
                                Choose your vendor:
                              </Typography>
                              <Grid container spacing={2} alignItems="center">
                                <Grid item xs={3}>
                                  <ServiceJobsCompanyPicker
                                    companyV={values?.company}
                                    setFieldValue={setFieldValue}
                                  />
                                </Grid>
                                <Grid item xs={3}>
                                  <ServiceJobsSystemPicker
                                    company={values.company}
                                    systemV={values?.system}
                                    setFieldValue={setFieldValue}
                                  />
                                </Grid>
                                <Grid item xs={3}>
                                  <ServiceJobsVersionPicker
                                    company={values.company}
                                    system={values.system}
                                    versionV={values?.version}
                                    setFieldValue={setFieldValue}
                                  />
                                </Grid>
                                <Grid item xs={3}>
                                  <FormField
                                    type="text"
                                    label="Property Code"
                                    name="propertyCode"
                                    value={values?.propertyCode}
                                    placeholder="Property Code"
                                    error={errors?.propertyCode && touched?.propertyCode}
                                    success={
                                      values?.propertyCode?.length > 0 && !errors?.propertyCode
                                    }
                                  />
                                </Grid>
                              </Grid>
                              <Typography
                                style={{ fontSize: "0.875rem", fontWeight: 400, marginTop: 16 }}
                              >
                                Add Jobs
                              </Typography>
                              <Grid container spacing={2} alignItems="center">
                                <Grid item xs={3}>
                                  <ServiceJobsCategoriesPicker
                                    setFieldValue={setFieldValue}
                                    serviceJobsObjV={values.serviceJobsObj}
                                  />
                                </Grid>
                                <Grid item xs={3}>
                                  <FormField
                                    type="text"
                                    label="Job Name"
                                    name="title"
                                    value={values?.title}
                                    placeholder="Name"
                                  />
                                </Grid>
                                <Grid item xs={3}>
                                  <FormField
                                    type="number"
                                    label="Job Code"
                                    name="code"
                                    value={values?.code}
                                    placeholder="Code"
                                  />
                                </Grid>
                                <Grid item xs={3}>
                                  <MDButton
                                    onClick={() => handleAddJob(values, setFieldValue)}
                                    variant="gradient"
                                    color="dark"
                                  >
                                    Add Job
                                  </MDButton>
                                </Grid>
                              </Grid>
                              {jobError && (
                                <Danger validation="Category, job name, and code fields are required!" />
                              )}
                              <MDBox mt={4}>
                                <MaterialTablePicker
                                  key={values.serviceJobs.length}
                                  tableRef={tableRef}
                                  title="Guest Companion"
                                  screen="GuestCompanion"
                                  data={values.serviceJobs}
                                  filteringOptions={false}
                                  columns={[
                                    "Job Category",
                                    "Job Subcategory",
                                    "Job Name",
                                    "Job Code",
                                  ]}
                                  isRowClickable={false}
                                  editableDelete={(oldData) =>
                                    handleDeleteJob(oldData, values, setFieldValue)
                                  }
                                  deleteRoleAction
                                  editableRole
                                />
                              </MDBox>
                            </Grid>
                          )}
                        </>
                      )}
                      {!detailPage && selectedTab === 1 && (
                        <Grid item xs={3}>
                          <Grid item>
                            <Typography
                              variant="h6"
                              component="div"
                              sx={{ display: "inline", mr: 2 }}
                            >
                              In Room Dining
                            </Typography>
                            <Switch
                              checked={values?.inRoomDining}
                              onChange={() => setFieldValue("inRoomDining", !values?.inRoomDining)}
                              color="primary"
                            />
                          </Grid>
                          <FormField
                            type="text"
                            label="Property Code"
                            name="inRoomDiningPropertyId"
                            value={values?.inRoomDiningPropertyId}
                            placeholder="Property Code"
                            error={
                              errors?.inRoomDiningPropertyId && touched?.inRoomDiningPropertyId
                            }
                            success={
                              values?.inRoomDiningPropertyId?.length > 0 &&
                              !errors?.inRoomDiningPropertyId
                            }
                          />
                        </Grid>
                      )}
                    </MDBox>
                  </CardContent>
                </Card>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </MDBox>
  );
};

GuestCompanion.defaultProps = {};

GuestCompanion.propTypes = {
  location: PropTypes.instanceOf(Object).isRequired,
};

export default GuestCompanion;
