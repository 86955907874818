/* eslint-disable no-underscore-dangle */
import {
  SET_LOCATION_LOADING,
  LOCATION_ADD_SUCCESS,
  LOCATION_FAIL,
  GET_LOCATIONS,
  GET_USER_PARTNER_LOCATIONS,
  CLEAR_ERRORS,
  DELETE_LOCATION,
  GET_ONE_LOCATION,
  UPDATE_LOCATION,
  GET_ONE_PARTNER,
  SET_LOCATION_APPEARANCE,
  UPDATE_FILTER,
  LOGOUT,
  UPDATE_LOCATION_TRANSLATION,
  SLIDE_LOCATION_ADD_SUCCESS,
  LOCATION_SLIDERS_FAIL,
  DELETE_LOCATION_SLIDER,
  SLIDE_LOCATION_UPDATE,
  UNPUBLISH,
  GET_LOCATION_TRANSLATION,
  PUBLISH,
  REFRESH_LOCATION,
  REFRESH_LOCATION_FAIL,
  SET_REFRESH_LOADING,
  LOCATION_JOBS_ADD_SUCCESS,
} from "../actions/Types";

const initialState = {
  loading: false,
  locations: [],
  error: null,
  added: false,
  location: null,
  sliders: [],
  translationLocation: {},
  refreshLoading: false,
  jobs: [],
};

const variable = (state = initialState, action) => {
  function upsert(array, item) {
    // (1)
    // make a copy of the existing array
    const newArray = array.slice();

    const i = newArray.findIndex((_item) => _item._id === item._id);
    if (i > -1) {
      newArray[i] = item;
      return newArray;
    }
    // (2)

    newArray.unshift(item);
    return newArray;
  }
  switch (action.type) {
    case LOCATION_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        locations: [action.payload.data, ...state.locations],
        added: true,
        location: null,
      };
    case SLIDE_LOCATION_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        sliders: action.payload?.data?.location?.slider,
        location: action.payload?.data?.location,
        added: true,
      };
    case SLIDE_LOCATION_UPDATE:
      return {
        ...state,
        sliders: action.payload?.data?.location?.slider,
        location: action.payload?.data?.location,
        added: true,
      };

    case DELETE_LOCATION_SLIDER:
      return {
        ...state,
        loading: false,
        sliders: state.location?.slider,
        location: action.payload?.data?.location,
        added: true,
      };
    case GET_ONE_LOCATION:
      return {
        ...state,
        loading: false,
        error: null,
        location: action.payload.data.data,
        added: false,
      };
    case GET_ONE_PARTNER: // Clear locations when partner is selcted to avoid pulling previous partner locations
      return {
        ...state,
        locations: [],
      };
    case GET_LOCATIONS:
      return {
        ...state,
        loading: false,
        error: null,
        locations: action.payload.data,
        added: false,
        location: null,
      };
    case GET_USER_PARTNER_LOCATIONS:
      return {
        ...state,
        loading: false,
        error: null,
        locations: action.payload.data.data,
        added: false,
        // location: null,
      };

    case SET_LOCATION_APPEARANCE:
      return {
        ...state,
        loading: false,
        error: null,
        location: action.payload,
      };
    case DELETE_LOCATION:
      return {
        ...state,
        loading: false,
        locations: state.locations.filter(
          (location) => location._id !== action.payload.data.location._id
        ),
      };
    case UPDATE_LOCATION:
    case UPDATE_FILTER:
      return {
        ...state,
        locations: state.locations.map((location) =>
          location._id === action.payload.data.location._id
            ? action.payload.data.location
            : location
        ),
        loading: false,
        location: action.payload.data.location,
        added: true,
      };
    case UPDATE_LOCATION_TRANSLATION:
      return {
        ...state,
        translationLocation: action.payload.data,
      };
    case GET_LOCATION_TRANSLATION:
      return {
        ...state,
        translationLocation: action.payload.data,
      };
    case REFRESH_LOCATION:
      return {
        ...state,
        refreshLoading: false,
      };
    case REFRESH_LOCATION_FAIL:
      return {
        ...state,
        refreshLoading: false,
        error: action.payload,
      };

    case LOCATION_JOBS_ADD_SUCCESS:
      return {
        ...state,
        loading: false,
        error: null,
        jobs: action.payload.data,
        added: true,
      };

    case LOCATION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case LOCATION_SLIDERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SET_LOCATION_LOADING:
      return {
        ...state,
        loading: true,
      };
    case SET_REFRESH_LOADING:
      return {
        ...state,
        refreshLoading: true,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
        loading: false,
        added: false,
      };
    case LOGOUT:
      return {
        loading: false,
        locations: [],
        error: null,
        added: false,
        location: null,
      };
    case UNPUBLISH:
      return {
        ...state,
        locations: upsert(state.locations, action.payload.data),
        loading: false,
      };
    case PUBLISH:
      return {
        ...state,
        locations: upsert(state.locations, action.payload.data),
        loading: false,
      };

    default:
      return state;
  }
};
export default variable;
