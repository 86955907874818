// // LogoSection.js
// import React from "react";
// import { Img, Section } from "@react-email/components";
// import { Tailwind } from "@react-email/tailwind";

// const LogoSection = () => (
//   <Tailwind>
//     <Section className="bg-[#F2F2F2] flex justify-center pb-5 mt-8">
//       <Img
//         src="https://email-assets.lokalee.app/email/emailImages/logo.png"
//         alt="Logo"
//         className="w-full h-auto mx-auto mt-5"
//       />
//     </Section>
//   </Tailwind>
// );

// export default LogoSection;
import React from "react";
import { Img, Section } from "@react-email/components";

const LogoSection = () => (
  <Section
    style={{
      backgroundColor: "#F2F2F2", // 'bg-[#F2F2F2]'
      display: "flex", // 'flex'
      justifyContent: "center", // 'justify-center'
      paddingBottom: "1.25rem", // 'pb-5'
      marginTop: "2rem", // 'mt-8'
    }}
  >
    <Img
      src="https://email-assets.lokalee.app/email/emailImages/logo.png"
      alt="Logo"
      style={{
        width: "100%", // 'w-full'
        height: "auto", // 'h-auto'
        marginLeft: "auto", // 'mx-auto' (left)
        marginRight: "auto", // 'mx-auto' (right)
        marginTop: "1.25rem", // 'mt-5'
      }}
    />
  </Section>
);

export default LogoSection;
