/* eslint-disable no-underscore-dangle */

// Import necessary libraries and components
import PropTypes from "prop-types";
import Select from "react-select";
import { styled } from "@mui/material/styles";

// Define the new options
const multiSelectOptions = [
  { value: "solo", label: "Solo" },
  { value: "couples", label: "Partner" },
  { value: "group", label: "Friends" },
  { value: "family", label: "Family" },
];

// Styled component using MUI's styled
const StyledSelect = styled(Select)(() => ({
  width: "100%",
  fontSize: "15px",
}));

// Custom styles for the options
const customOptionStyles = (base) => ({
  ...base,
  fontSize: "15px",
});

const CompanionsPicker = ({
  handleSelectionChange,
  selectedValues,
  setFieldValue,
  options = multiSelectOptions, // Default to the new options
  placeholder = "Select options",
}) => {
  console.log(">selectedValues", selectedValues);
  return (
    <StyledSelect
      isMulti
      options={options}
      placeholder={placeholder}
      value={selectedValues}
      onChange={(selectedOptions) => {
        setFieldValue("persona", selectedOptions);
        handleSelectionChange(selectedOptions);
      }}
      getOptionValue={(option) => `${option.value}`}
      getOptionLabel={(option) => `${option.label}`}
      styles={{
        menuPortal: (base) => ({ ...base, zIndex: 9999 }),
        option: customOptionStyles,
      }}
      isSearchable
      menuPortalTarget={document.body}
    />
  );
};

// Setting default props
CompanionsPicker.defaultProps = {
  handleSelectionChange: () => {},
  selectedValues: [],
  setFieldValue: () => {},
  options: multiSelectOptions,
  placeholder: "Select options",
};

// Typechecking props for CompanionsPicker
CompanionsPicker.propTypes = {
  handleSelectionChange: PropTypes.func,
  selectedValues: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  setFieldValue: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
  placeholder: PropTypes.string,
};

export default CompanionsPicker;
