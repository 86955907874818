/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */

// Material Dashboard 2 PRO React examples

// libraries
import { createRef, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form } from "formik";
import { format, isSameDay, subDays } from "date-fns";

// custom components
import Spinner from "components/Spinner";
import Danger from "components/Danger";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import DynamicDateRange from "components/DynamicDateRange";
import DashboardLayout from "layouts/DashboardLayout";
import DashboardNavbar from "components/DashboardNavbar";
import Footer from "components/Footer";
import MDButton from "components/MDButton";
import MaterialTablePicker from "components/MaterialTablePicker";

// redux actions
import {
  getProductionReports,
  setLoading,
  clearHeaderAndReports,
} from "store/actions/reportActions";
import LocationReportPicker from "components/AutoComplete/ReportsPickers/LocationReportPicker";
import { getLocations, getUserPartnerLocations } from "store/actions/locationAction";

// material ui components
import { Grid } from "@mui/material";
import CurrencyPicker from "components/AutoComplete/CurrencyPicker";
import pdfProductionReport from "./PdfProductionReport";

// Schemas
import initialValues from "./ProductionReportsList/schemas/initialValues";
import validations from "./ProductionReportsList/schemas/validations";
import ItemsTable from "./ProductionReportsList/ItemsTable";

const ProductionReports = () => {
  //   const { formField, values, errors, touched, setFieldValue } = formData;
  //   const { location } = formField;
  //   const { location: locationV } = values;

  const tableRef = createRef();
  const dispatch = useDispatch();
  const formRef = useRef();

  const productionReports = useSelector((state) => state.reports.productionReports);
  const locations = useSelector((state) => state.locations.locations);
  const loading = useSelector((state) => state.reports.loading);
  const user = useSelector((state) => state.users.user);

  const currentValidation = validations[0];
  const [hideDaterange, setHideDaterange] = useState(true);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [dateRange, setDateRange] = useState([
    {
      startDate: subDays(
        new Date(
          Date.UTC(new Date().getUTCFullYear(), new Date().getUTCMonth(), new Date().getUTCDate())
        ),
        7
      ),
      endDate: new Date(),
      key: "selection",
    },
  ]);

  const sleep = (ms) =>
    new Promise((resolve) => {
      setTimeout(resolve, ms);
    });
  const submitForm = async (values, actions) => {
    await sleep(1000);

    dispatch(setLoading());
    dispatch(
      getProductionReports(
        {
          dateRange,
          slug: selectedLocation?.slug,
          locationId: selectedLocation?._id,
        },
        selectedCurrency?.name
      )
    );

    // actions.setSubmitting(false);
    // actions.resetForm();
  };

  const handleSubmit = (values, actions) => {
    if (!selectedLocation) {
      return;
    }
    submitForm(values, actions);
  };

  const handleDateChange = (date, event) => {
    if (date?.selection?.startDate && isSameDay(date.selection.startDate, date.selection.endDate)) {
      const newStartDate = date.selection.startDate;
      const newEndDate = dateRange[0].endDate;
      setDateRange([{ startDate: newStartDate, endDate: newEndDate }]);
      //   setIsLabel(false);
    } else {
      const newStartDate = date?.range1?.startDate
        ? date?.range1.startDate
        : date?.selection?.startDate;
      const newEndDate = date?.range1?.endDate ? date?.range1.endDate : date?.selection?.endDate;
      setDateRange([{ startDate: newStartDate, endDate: newEndDate }]);
    }
  };

  useEffect(() => {
    setLoading();
    if (user?.role === "partner") {
      dispatch(getUserPartnerLocations(user?.partner?._id));
    } else {
      dispatch(getLocations());
    }
    // eslint-disable-next-line
  }, []);

  const handleLocationChange = (value) => {
    if (!value) {
      setSelectedLocation(null);
    } else {
      setSelectedLocation(value);
    }
  };

  useEffect(() => {
    dispatch(clearHeaderAndReports());
  }, [selectedLocation]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid item xs={12} lg={8}>
        <Formik
          initialValues={initialValues}
          validationSchema={currentValidation}
          onSubmit={handleSubmit}
          innerRef={(f) => {
            formRef.current = f;
          }}
        >
          {({ values, errors, touched, isSubmitting, formData, setFieldValue }) => (
            <Form autoComplete="off">
              <Grid container spacing={3}>
                {" "}
                <Grid item mt={3} xs={6}>
                  <MDTypography variant="h6" color="text" fontWeight="regular">
                    Location
                  </MDTypography>
                  <MDBox
                    style={{
                      marginTop: "8px",
                      backgroundColor: "white",
                      borderRadius: "8px",
                    }}
                  >
                    <LocationReportPicker
                      locations={locations}
                      setFieldValue={setFieldValue}
                      location={selectedLocation}
                      locationReportPage
                      handleChange={handleLocationChange}
                    />
                  </MDBox>
                  {errors?.location && touched?.location && (
                    <Danger validation={errors?.location} />
                  )}
                </Grid>
                <Grid item mt={3} xs={6}>
                  <MDTypography variant="h6" color="text" fontWeight="regular">
                    Currency
                  </MDTypography>
                  <MDBox
                    style={{
                      marginTop: "8px",
                      backgroundColor: "white",
                      //   borderRadius: "8px",
                    }}
                  >
                    <CurrencyPicker
                      currency={selectedCurrency}
                      handleSelectCurrency={(event) => {
                        setSelectedCurrency(event);
                      }}
                    />
                  </MDBox>
                </Grid>
                <Grid item xs={6}>
                  <DynamicDateRange
                    defaultValue={dateRange}
                    selectedOption={dateRange}
                    isTodayIncluded
                    handleDateChange={handleDateChange}
                    hideDaterange={hideDaterange}
                    hideDateRangeFn={setHideDaterange}
                  />
                  <MDBox style={{ fontSize: "small" }}>
                    Selected Date Range: {dateRange[0]?.startDate.toDateString()} to{" "}
                    {dateRange[0].endDate.toDateString()}
                  </MDBox>
                </Grid>
              </Grid>
              <MDBox
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  textAlign: "right",
                  // marginRight: "45px",
                  marginTop: "20px",
                }}
              >
                {" "}
                <div style={{ marginTop: "20px" }}>
                  <MDButton
                    onClick={(val, actions) => {
                      if (Object.keys(errors).length === 0) {
                        setHideDaterange(true);
                      }
                    }}
                    type="submit"
                    variant="gradient"
                    color="dark"
                  >
                    Generate report
                  </MDButton>
                </div>
              </MDBox>
              {selectedLocation && productionReports?.docInvoices?.length > 0 && (
                <div
                  style={{
                    width: "100%",
                    height: "21px",
                    marginTop: "15px",
                    backgroundColor: "#d3d3d3",
                  }}
                />
              )}
              {productionReports?.summary && (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between", // Ensures the items are spaced out
                    alignItems: "center", // Aligns both items vertically
                    marginTop: "15px",
                  }}
                >
                  <div
                    style={{
                      textAlign: "left",
                      marginLeft: "45px", // Add some margin from the table's left side
                    }}
                  >
                    <label
                      style={{
                        color: "black",
                        fontSize: "15px",
                        fontWeight: "bold",
                      }}
                    >
                      Total Commission: {productionReports?.summary?.totalNetProfit}
                    </label>
                  </div>

                  <div
                    style={{
                      textAlign: "right",
                      marginRight: "45px", // Add some margin from the table's right side
                    }}
                  >
                    <label
                      style={{
                        color: "black",
                        fontSize: "15px",
                        fontWeight: "bold",
                      }}
                    >
                      Partner Total Commission: {productionReports?.summary?.totalComission}
                    </label>
                  </div>
                </div>
              )}

              <Grid item xs={12} mt={3}>
                {productionReports?.docInvoices && selectedLocation?._id !== null && (
                  // eslint-disable-next-line react/no-array-index-key
                  <MDBox sx={{ m: 5 }}>
                    <MaterialTablePicker
                      screen="ProductionReportsList"
                      title="Orders"
                      searchField="productionReports"
                      currentUrl="productionReports"
                      tableRef={tableRef}
                      columns={[
                        "Invoice Number",
                        "Customer Name",
                        "Quantity Number",
                        "Purchase Date",
                        "Booking Date",
                        "Subtotal Amount",
                        "Discount Amount",
                        "Order Total",
                        "Com",
                      ]}
                      isRowClickable={false}
                      data={productionReports?.docInvoices}
                      // eslint-disable-next-line react/no-unstable-nested-components
                      detailPanel={(rowData) => (
                        <MDBox sx={{ ml: 6, mr: 4 }}>
                          <ItemsTable items={rowData.rowData.items} />
                        </MDBox>
                      )}
                      tableInside
                      isFreeDownloadAction
                      isLoading={loading}
                      filteringOptions={false}
                      isExportable={false}
                      actionsOnClick={() => {
                        pdfProductionReport(productionReports);
                      }}
                      actionsRole
                      actionsName="productionReports"
                    />
                  </MDBox>
                )}
              </Grid>
            </Form>
          )}
        </Formik>
      </Grid>

      <Footer />
      <Spinner loading={loading} />
    </DashboardLayout>
  );
};

export default ProductionReports;
