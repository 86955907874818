import checkout from "./formCompanion";

const {
  formField: { jobName, jobCode, propertyCode, inRoomDiningPropertyId, company, system, version },
} = checkout;

const initialValuesCompanion = {
  [jobName.name]: "",
  [jobCode.name]: "",
  [propertyCode.name]: "",
  [inRoomDiningPropertyId.name]: "",
  [company.name]: "",
  [system.name]: "",
  [version.name]: "",
};

export default initialValuesCompanion;
